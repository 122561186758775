import * as React from "react"

import LayoutWhite from "../components/layoutWhite"
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha'
import axios from "axios"

class ContactoPage extends React.Component {
  constructor(props) {
    super(props);

	this.state = {
	  valid: true,
      isLoading: false,
	  response: '',
	  Name_First: {
		value: '',
		valid: true
	  },
	  Name_Last: {
		value: '',
		valid: true
	  },
	  SingleLine2: {
		value: '',
		valid: true
	  },
	  Email: {
		value: '',
		valid: true
	  },
	  PhoneNumber_countrycode: {
		value: '',
		valid: true
	  },
	  MultipleChoice: {
		value: '',
		valid: true
	  },
	  MultiLine: {
		value: '',
		valid: true
	  },
	  codigo: {
		value: '',
		valid: true
	  }
    };

	this.handleInputChange = this.handleInputChange.bind(this);
	//this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  componentDidMount() {
	loadCaptchaEnginge(6, '#eee', '#014880');
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: {
		value: value,
		valid: value.trim() != '',
	  },
	  //valid: value.trim() != ''
    });
  }

	handleSubmit(event) {
		//event.preventDefault();
		this.setState({ isLoading: true, valid: true });
		
		if(this.state.Name_First.value == '' 
        || this.state.Name_Last.value == ''
        || this.state.SingleLine2.value == '' 
        || this.state.Email.value == '' 
        || this.state.PhoneNumber_countrycode.value == ''
        || this.state.MultipleChoice.value == '' 
        || this.state.MultiLine.value == ''
        || this.state.codigo.value == ''){
			this.setState({
				valid: false,
				isLoading: false,
Name_First: {
	value: this.state.Name_First.value,
	valid: this.state.Name_First.value != ''
},
Name_Last: {
	value: this.state.Name_Last.value,
	valid: this.state.Name_Last.value != ''
},
SingleLine2: {
value: this.state.SingleLine2.value,
	valid: this.state.SingleLine2.value != ''
},
Email: {
value: this.state.Email.value,
	valid: this.state.Email.value != ''
},
PhoneNumber_countrycode: {
value: this.state.PhoneNumber_countrycode.value,
	valid: this.state.PhoneNumber_countrycode.value != ''
},
MultipleChoice: {
value: this.state.MultipleChoice.value,
	valid: this.state.MultipleChoice.value != ''
},
MultiLine: {
value: this.state.MultiLine.value,
	valid: this.state.MultiLine.value != ''
}, 
codigo: {
value: this.state.codigo.value,
	valid: this.state.codigo.value != ''
}, 

			})
			
			event.preventDefault();
			
return false;
		}
		
		if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.Email.value) != true){
			this.setState({
				valid: false,
				isLoading: false,
				Email: {
				    value: this.state.Email.value,
					valid: false
				}
			})
			
			event.preventDefault();
			return false;
		}
		if(validateCaptcha(this.state.codigo.value)!=true){
			this.setState({
				valid: false,
				isLoading: false,
				codigo: {
				    value: '',
					valid: false
				}
			})
			event.preventDefault();
			return false;
		}

		//const consulta = { 
		//	Name_First:   this.state.Name_First.value, 
		//	Name_Last:   this.state.Name_Last.value, 
		//	SingleLine2:     this.state.SingleLine2.value, 
		//	Email:    this.state.Email.value, 
		//	PhoneNumber_countrycode: this.state.PhoneNumber_countrycode.value, 
		//	MultipleChoice:   this.state.MultipleChoice.value, 
		//	MultiLine:  this.state.MultiLine.value, 
		//};
    }

  render() {
	return (
	<LayoutWhite
		seccion="contacto"	
		title="Contacto"	
		linkTo="experienciaContainer"
		>		
		<div id="whiteContainer" className="row">
			<div className="col-12"
					data-aos='fade-up'
					data-aos-duration="600" data-aos-once="true">
				<h1 className="col">Contacto</h1>
				
				<p className="col">Comuníquese con nosotros, nos pondremos en contacto a la brevedad</p>
				
				<form onSubmit={this.handleSubmit} className="form" 
				action="https://forms.zohopublic.com/mgarciadutriez/form/RaicoWeb/formperma/0s3epdmsFC7cbvcjhluQ8zQm3-OUznRPgnMEl-B6TJg/htmlRecords/submit" method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>
					<div className="form-group">
						<div className="col col-sm-6 mt-3">
							<label className="form-label">Nombre</label>
							<input className={"form-control "+(!this.state.Name_First.valid ? 'error' : '')}  name="Name_First" type="text" value={this.state.Name_First.value} onChange={this.handleInputChange}></input>
						</div>
					</div>
					<div className="form-group">
						<div className="col col-sm-6 mt-3">
							<label className="form-label">Apellido</label>
							<input className={"form-control "+(!this.state.Name_Last.valid ? 'error' : '')}  name="Name_Last" type="text" value={this.state.Name_Last.value} onChange={this.handleInputChange}></input>
						</div>
					</div>
					<div className="form-group">
						<div className="col col-sm-6 mt-3">
							<label className="form-label">País</label>
							<input className={"form-control "+(!this.state.SingleLine2.valid ? 'error' : '')}  name="SingleLine2" type="text" value={this.state.SingleLine2.value} onChange={this.handleInputChange}></input>
						</div>
					</div>
					<div className="form-group">
						<div className="col col-sm-6 mt-3">
							<label className="form-label">Correo electrónico</label>
							<input className={"form-control "+(!this.state.Email.valid ? 'error' : '')}  name="Email" type="text" value={this.state.Email.value} onChange={this.handleInputChange}></input>
						</div>
					</div>
					<div className="form-group">
						<div className="col col-sm-6 mt-3">
							<label className="form-label">Teléfono</label>
							<input className={"form-control "+(!this.state.PhoneNumber_countrycode.valid ? 'error' : '')}  name="PhoneNumber_countrycode" type="text" value={this.state.PhoneNumber_countrycode.value} onChange={this.handleInputChange}></input>
						</div>
					</div>
					<div className="form-group">
						<div className="col col-sm-6 mt-3">
							<label className="form-label">Seleccione una opción</label>
							<select className={"form-control "+(!this.state.MultipleChoice.valid ? 'error' : '')}  name="MultipleChoice" value={this.state.MultipleChoice.value} onChange={this.handleInputChange}>
								<option value=""></option>
								<option value="Importación">Importación</option>
								<option value="Exportación">Exportación</option>
								<option value="Marítimo">Marítimo</option>
								<option value="Nacional">Nacional</option>
								<option value="Otro">Otro</option>
							</select>
						</div>
					</div>

					<div className="form-group">
						<div className="col col-sm-6 mt-3">
							<label className="form-label">Mensaje</label>
							<textarea className={"form-control "+(!this.state.MultiLine.valid ? 'error' : '')}  name="MultiLine" value={this.state.MultiLine.value} onChange={this.handleInputChange} rows="7" />
						</div>
					</div>

					<div className="form-group">
						<div className="col col-sm-6 mt-3">
						
							<label className="form-label">Código de verificación *</label>
							<input className={"form-control "+(!this.state.codigo.valid ? 'error' : '')}  id="user_captcha_input" type="text" name="codigo" value={this.state.codigo.value} onChange={this.handleInputChange}></input>
						
							<LoadCanvasTemplate 
							reloadText='<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/></svg>'			
							/>
					   </div>

						<div className="form-group">
							<div className="col col-sm-6 mt-3">
								<span className="formMessage"
								style={{ display: 'block' }}>{this.state.response}</span>

								{this.state.isLoading ?
								<div className="success circle"><div className="spinner-border" role="status">
								  <span className="sr-only "></span>
								</div></div>
								:
								<input type="submit" className="btn btnBlue" value="Enviar" />
								}
								{!this.state.valid &&
								<p className="error mt-3">Por favor, complete los datos que faltan.</p>
								}
							</div>
						</div>
					</div>
				</form>
			</div>			
		</div>
	  </LayoutWhite>
	)}
}

export default ContactoPage